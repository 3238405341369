jQuery(function($) {
    var $forms = $('.js-relation-form'),
        templates = {};

    $('.js-relation-form-row').each(function(idx, el) {
        var $template = $(el),
            resource = $template.data('resource');

        templates[resource] = Handlebars.compile($template.html());
    });

    $(document).on('click', '.js-add-row', function() {
        var $this = $(this),
            $table = $this.closest('.form-group').find('.js-relation-form'),
            resource = $table.data('resource'),
            $lastRow = $table.find('.js-row').last(),
            $row = $(templates[resource]().replace(/%%idx%%/, $lastRow.index() + 1));

        $row.addClass('new');

        if($lastRow.length) {
            $row.insertAfter($lastRow);
        } else {
            $table.find('tbody').append($row);
        }

        $row.find('.selectpicker').selectpicker();
        $row.find('.date-picker').datetimepicker({
            format: 'DD/MM/YYYY',
            locale: 'ru'
        });

        $table.find('.js-row').each(function(idx, row) {
            var $row = $(row);

            $row.find('.form-control').each(function(idx, input) {
                var $input = $(input),
                    name = $input.attr('name');

                if (name) {
                    $input.attr('name', name.replace(/[\d+]/, $row.index()));
                }
            });
        });

        return false;
    });

    $forms.each(function(i, form) {
        var $form = $(form);

        $form.on('click', '.js-remove-row', function() {
            var $this = $(this),
                $row = $this.closest('.js-row'),
                $mainForm = $row.closest('form');

            if ($mainForm.data('create') == 1 || $row.hasClass('new')) {
                $row.remove();

                return false;
            }

            var oldText = $this.text();

            $this.text($this.data('text'));
            $this.data('text', oldText);

            $row.data('removed', $row.data('removed') != 1 ? 1 : 0);

            if($row.data('removed')) {
                $row.find('td:not(.js-td-removed)').find('input, select, textarea').prop('disabled', true);
            } else {
                $row.find('td:not(.js-td-removed)').find('input, select, textarea').prop('disabled', false);
            }

            $row.find('td:not(.js-td-removed)').toggleClass('hidden');
            $row.find('.js-td-removed')
                .toggleClass('hidden')
                .attr('colspan', $row.find('td:not(.js-td-removed)').length);

            return false;
        });

        $form.on('click', '.js-undo-link', function() {
            $(this).closest('.js-row').find('.js-remove-row').click();

            return false;
        });

        $form.on('input change', '.form-control', function() {
            var $this = $(this),
                $row = $this.closest('.js-row'),
                val = $this.val() || $this.text(),
                changed = (val != $this.data('initial') ? 1 : 0);

            $row.find('[data-changed]').val(changed);
        });
    });

});