jQuery(function($) {
    var delay = (function(){
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();


    // http://stackoverflow.com/a/6394014
    function path(obj, str) {
        var parts = str.split('.'),
            newObj = obj[parts[0]];

        if (parts[1]) {
            parts.splice(0, 1);

            return path(newObj, parts.join('.'));
        }

        return newObj;
    }

    var progressIconClass = 'zmdi-hourglass-alt',
        clipboard = null,
        $body = $('body'),
        $document = $(document);

    /**
     * Bind events first
     * reference: https://github.com/rstaib/jquery-bootgrid/issues/87
     */
    $('#crm-bootgrid').on('initialize.rs.jquery.bootgrid', function (e)
    {
        var $bootgrid = $(this),
            resource = $bootgrid.data('resource') || 'common',
            tableHead = $(this).find('thead > tr').first();

        tableHead.children().each(function ()
        {
            var $this = $(this),
                column = $this.data('column-id'),
                storedVisible = localStorage.getItem('bootgrid.' + resource + '.' + column + '.visible'),
                visible = storedVisible !== 'false', // equal with: storedVisible === 'false' ? false : true
                storedOrderColumn =  localStorage.getItem('bootgrid.' + resource + '.order_column'),
                storedOrder = localStorage.getItem('bootgrid.' + resource + '.order_direction'),
                order = (storedOrder === 'asc' || storedOrder === 'desc') ? storedOrder : null;

            $this.data('visible', visible);

            if(storedOrderColumn == column) {
                $this.data('order', order);
            } else {
                $this.data('order', null);
            }

        });

    }).on('initialized.rs.jquery.bootgrid', function (e)
    {
        var oldTableSortableSelector = $('th[data-column-id] .sortable');
        oldTableSortableSelector.each(function(idx, el) {
            var $el = $(el);
            $el.removeClass('sortable');
        });
        var newTableSortableSelector = oldTableSortableSelector.parent();
        newTableSortableSelector.each(function(idx, el) {
            var $el = $(el);
            $el.addClass('sortable');
        });
        var $bootgrid = $(this),
            resource = $bootgrid.data('resource') || 'common',
            bootgrid = $bootgrid.data('.rs.jquery.bootgrid'),
            tableHead = $(this).find('thead > tr').first(),
            headerSelector = "." + $.trim(bootgrid.options.css.header).replace(/\s+/gm, "."),
            sortableSelector = "." + $.trim(bootgrid.options.css.sortable).replace(/\s+/gm, "."),
            dropDownMenuItemsSelector = "." + $.trim(bootgrid.options.css.dropDownMenuItems).replace(/\s+/gm, ".");
        // saving column visiblity to localStorage
        $(headerSelector).on('change', dropDownMenuItemsSelector + ' input[type="checkbox"]', function(e) {
            var $this = $(this),
                column = $this.attr('name'),
                checked = $this.prop('checked');

            localStorage.setItem('bootgrid.' + resource + '.' + column + '.visible', checked);
        });

        // saving column ordering to localStorage
        $(tableHead).on('click', sortableSelector, function(e) {
            var $this = $(this),
                $column = $this.closest('th'),
                column = $column.data('column-id'),
                order = bootgrid.sortDictionary[column];

            localStorage.setItem('bootgrid.' + resource + '.order_column', column);
            localStorage.setItem('bootgrid.' + resource + '.order_direction', order);
        });

    }).on('loaded.rs.jquery.bootgrid', function (e)
    {
        var $bootgrid = $(this);

        $('.lightbox').lightGallery();

        $('.js-btn-toolbar').each(function(idx, el) {
            var $el = $(el),
                trashed = $el.is('[data-trashed]');

            if ($el.is('[data-trashed]')) {
                var $tds = $el.closest('td').prevAll();

                $tds.addClass('td-muted');
                $tds.attr('title', 'Entity trashed');
            }
        });

        var actionIconSelector = '.js-action-icon';

        $('.js-action-trash').on('click', function() {
            var $this = $(this),
                $icon = $this.find(actionIconSelector),
                iconClass = 'zmdi-delete',
                $parent = $this.parent(),
                $edit = $parent.find('.js-action-edit'),
                $restore = $parent.find('.js-action-restore'),
                $destroy = $parent.find('.js-action-destroy');

            $this.attr('disabled', true);
            $icon.removeClass(iconClass)
                .addClass(progressIconClass);

            $.ajax({
                url: $this.data('url'),
                method: 'post',
                data: {
                    _token: $this.data('token'),
                    _method: 'delete'
                },
                dataType: 'json',
                success: function(response) {
                    $this.addClass('hidden');
                    $restore.removeClass('hidden');
                    $destroy.removeClass('hidden');

                    var $tds = $this.closest('td').prevAll();

                    $tds.addClass('td-muted');
                    $tds.attr('title', 'Entity trashed');

                    $edit.addClass('hidden');
                },
                error: function(response) {
                    console.log(response);
                },
                complete: function() {
                    $this.attr('disabled', false);
                    $icon.addClass(iconClass)
                        .removeClass(progressIconClass);
                }
            });
        });

        $('.js-action-restore').on('click', function() {
            var $this = $(this),
                $icon = $this.find(actionIconSelector),
                iconClass = 'zmdi-undo',
                $parent = $this.parent(),
                $edit = $parent.find('.js-action-edit'),
                $trash = $parent.find('.js-action-trash'),
                $destroy = $parent.find('.js-action-destroy');

            $this.attr('disabled', true);
            $icon.removeClass(iconClass)
                .addClass(progressIconClass);

            $.ajax({
                url: $this.data('url'),
                method: 'post',
                data: {
                    _token: $this.data('token'),
                    _method: 'put'
                },
                success: function(response) {
                    $this.addClass('hidden');
                    $trash.removeClass('hidden');
                    $destroy.addClass('hidden');

                    var $tds = $this.closest('td').prevAll();

                    $tds.removeClass('td-muted');
                    $tds.attr('title', '');

                    $edit.removeClass('hidden');
                },
                error: function(response) {
                    console.log(response);
                },
                complete: function() {
                    $this.attr('disabled', false);
                    $icon.addClass(iconClass)
                        .removeClass(progressIconClass);
                }
            });
        });

        $('.js-action-destroy').on('click', function() {
            var $this = $(this);

            $this.attr('disabled', true);
            $this.find(actionIconSelector)
                .removeClass('zmdi-close')
                .addClass(progressIconClass);

            $this.closest('tr').slideUp(function() {
                $(this).remove();
            });

            $.ajax({
                url: $this.data('url'),
                method: 'post',
                data: {
                    _token: $this.data('token'),
                    _method: 'delete',
                    _force: true
                },
                success: function(response) {
                    $this.closest('tr').slideUp(function() {
                        $(this).remove();
                    });
                },
                error: function(response) {
                    console.log(response);
                }
            });
        });

        $('.js-public-url-button').on('click', function(event) {
            if (event.shiftKey || event.ctrlKey || event.metaKey) {
                window.open($(this).data('clipboard-text'), '_blank');

                return false;
            }
        });

        clipboard = new Clipboard(document.getElementsByClassName('js-public-url-button'));
        clipboard.on('success', function(arg) {
            notify($(arg.trigger).data('success'), 'success');
        });

        $document.off('keydown');

        $('.js-datagrid-pattern').on('input', function(event) {
            delay(function() {
                $bootgrid.bootgrid('reload');
            }, 500);

            event.stopPropagation();

            return false;
        });

        $('.js-datagrid-filter').each(function(idx, el) {
            var $filter = $(el),
                value = $filter.val();

            $filter.on('change', function() {
                var $this = $(this),
                    thisVal = $this.val();

                if (thisVal != value) {
                    value = thisVal;

                    $bootgrid.bootgrid('reload');
                }
            });
        });
    }).bootgrid({
        ajax: true,
        ajaxSettings: {
            method: 'GET',
            cache: false
        },
        requestHandler: function(request) {
            request.patterns = {};

            $('.js-datagrid-pattern').each(function(idx, el) {
                request.patterns[el.name] = el.value;
            });

            request.filters = {};

            $('.js-datagrid-filter').each(function(idx, el) {
                if (typeof el.name !== 'undefined') {
                    var name = el.name,
                        indexOfBracket = name.indexOf('[');

                    if (indexOfBracket > -1) {
                        name = name.substr(0, indexOfBracket);
                    }

                    request.filters[name] = $(el).val();
                }
            });

            return request;
        },
        templates: {
            actionDropDownCheckboxItem: "<li><div class=\"checkbox\"><label class=\"{{css.dropDownItem}}\"><input name=\"{{ctx.name}}\" type=\"checkbox\" value=\"1\" class=\"{{css.dropDownItemCheckbox}}\" {{ctx.checked}} /> {{ctx.label}}<i class=\"input-helper\"></i></label></div></li>",
            select: "<div class=\"checkbox\"><label><input name=\"select\" type=\"{{ctx.type}}\" class=\"{{css.selectBox}}\" value=\"{{ctx.value}}\" {{ctx.checked}} /><i class=\"input-helper\"></i></label></div>"
        },
        labels: {
            all: "Все",
            infos: "Показываются с {{ctx.start}} по {{ctx.end}} из {{ctx.total}} записей",
            loading: "Загрузка...",
            noResults: "Ничего не найдено!",
            refresh: "Обновить",
            search: "Поиск"
        },
        formatters: {
            imageLightbox: function (column, row) {
                var image = row[column.id];
                var template = '<div class="lightbox">' +
                    '<div data-src="[original]">' +
                    '<div class="lightbox-item">' +
                    '<img src="[thumbnail]" alt="">' +
                    '</div>' +
                    '</div>' +
                    '</div>';

                if(!image || !image[column.id]) {
                    return '';
                }

                return template
                        .replace('[original]', image.original.url)
                        .replace('[thumbnail]', image[column.id].url);
            },
            hasOne: function (column, row) {
                var relation = row[column.id.substr(0, column.id.lastIndexOf('_id'))];

                return (relation ? (relation.description || relation.name || relation.id || '') : '');
            },
            phone: function (column, row) {
                var phone = row[column.id],
                    phone_formatted = phone.replace(/[^\d\*+#]/gi, ''),
                    template = '<a href="tel:[phone_formatted]">[phone]</a>';

                return template
                        .replace('[phone]', phone)
                        .replace('[phone_formatted]', phone_formatted);
            },
            float: function (column, row) {
                var value = row[column.id];

                return parseFloat(value).toFixed(4).replace(/0+$/,'');
            },
            path: function (column, row) {
                return path(row, column.id);
            },
            boolean: function (column, row) {
                return row[column.id] == 1 ? '<strong class="label label-danger">YES</strong>' : 'NO';
            },
            actions: (typeof DatagridBuilder !== 'undefined' ? DatagridBuilder.actions : function(){})
        }
    });

    $('.js-submit-button').on('click', function() {
        $('.html-editor').each(function(idx, el) {
            el.value = $(el).code();
        });
    });

    $('.js-reference').on('loaded.bs.select', function (e) {
        var $this = $(this),
            referenceUrl = $this.data('reference-url'),
            referenceTitle = $this.data('reference-title'),
            selectpicker = $this.data('selectpicker');

        if(referenceUrl) {
            $('<button/>')
                .attr('type', 'button')
                .addClass('btn btn-primary waves-effect bs-reference-create')
                .html('<i class="zmdi zmdi-plus"></i>')
                .data('title', referenceTitle)
                .data('url', referenceUrl)
                .attr('title', referenceTitle)
                .on('click', function() {
                    var $this = $(this),
                        $icon = $this.find('i.zmdi');

                    $icon.addClass(progressIconClass)
                        .removeClass('zmdi-plus');

                    $.ajax({
                        url: $this.data('url'),
                        success: function(response) {
                            $icon.addClass('zmdi-plus')
                                .removeClass(progressIconClass);

                            swal({
                                title: $this.data('title'),
                                html: response,
                                showConfirmButton: false,
                                customClass: 'resource-form-modal'
                            });
                        }
                    });
                })
                .insertAfter(selectpicker.$searchbox);
        }

    });

    $(document).on('submit', '.resource-form-modal', function(e) {
        e.preventDefault();

        setTimeout(function(){
            swal("Ajax request finished!")
        }, 2000);
    });

    $('.js-notification').each(function(idx, el) {
        var $notification = $(el),
            message = $notification.data('message') || $notification.html();

        notify($notification.html(), $notification.data('type'));
    });

    $body.on('click', '.toggle-switch', function() {
        var $input = $(this).find('> input');

        $input.val(+$input.is(':checked'));
    });

    $('input[name="phone"]').mask('+7 (000) 000-00-00');
    $('input[name="phone_alt"]').mask('+7 (000) 000-00-00');

});

function notify(message, type) {
    jQuery.bootstrapGrowl(message, {
        type: type,
        align: 'center',
        offset: {from: 'top', amount: 120},
        width: 450
    });
}